<template>
	<div class="view view--main main">
		<router-view class="main__header" name="header" />
		<router-view class="main__content" name="content" />
		<router-view class="main__footer" name="footer" />
	</div>
</template>

<script>
export default {
	name: 'viewMain',
}
</script>

<style scoped lang="scss">
.main {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	width: 100%;

	&__content {
		flex: 1;
	}
}
</style>
