<template>
	<header class="main-header">
		<div class="main-header__content content">
			<router-link :to="{name: 'main'}" class="main-header__logo">
				<img src="@/assets/northern_star_seed_logo.png" alt="Northern Star Seed LLC" />
			</router-link>
			<div class="main-header__nav" :class="{'main-header__nav--open': navOpen}">
				<button class="main-header__nav-button material-icons" @click="navOpen = !navOpen">menu</button>
				<router-link class="main-header__nav-item" active-class="main-header__nav-item--active" v-for="(item, key) in navItems" :key="key" :to="item.link" @click.native="navOpen = false">
					{{ item.label }}
				</router-link>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'mainHeader',
	data: () => ({
		navOpen: false,
	}),
	computed: {
		navItems() {
			let items = []
			let seeds = this.$store.getters['seeds/seeds']
			seeds.forEach(seed => {
				items.push({
					label: seed.label,
					link: {
						name: 'seed',
						params: {
							seedId: seed.key,
						},
					}
				})
			})

			items.push({
				label: 'Contact Us',
				link: {
					name: 'contact',
				}
			})

			return items
		},
	},
}
</script>

<style scoped lang="scss">
.main-header {
	&__content {
		@include modules.gutter('padding-bottom');
		@include modules.gutter('padding-top');
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	&__nav {
		display: flex;
		flex: 1;
		align-items: flex-end;
		justify-content: flex-end;
		flex-wrap: wrap;

		@include modules.media-query('phone') {
			background-color: rgba(255, 255, 255, 0.8);
			backdrop-filter: blur(2px);
			flex-direction: column;
			padding: 1rem;
			position: absolute;
			right: 0rem;
			top: 1rem;
			z-index: 99;

			.main-header__nav-item {
				display: none;
			}
		}

		&--open {
			@include modules.media-query('phone') {
				.main-header__nav-item {
					display: inline-block;
				}
			}
		}

		&-button {
			border: 0;
			background: none;
			color: modules.color_('secondary');
			display: none;
			margin-bottom: 0.5rem;

			@include modules.media-query('phone') {
				display: block;
			}
		}

		&-item {
			@include modules.fontSize(19px);
			border-bottom: 3px solid transparent;
			color: modules.color_('secondary');
			font-weight: 500;
			line-height: 1.6;
			margin-left: 1rem;
			text-decoration: none;
			text-transform: uppercase;
			transition: border 250ms;

			@include modules.media-query('tablet') {
				@include modules.fontSize(15px);
			}

			&:hover {
				border-bottom-color: modules.color_('secondary');
			}

			&--active {
				border-bottom-color: modules.color_('primary');
			}
		}
	}
}
</style>
