<template>
	<footer class="main-footer">
		<div class="main-footer__content content">
			<div class="main-footer__meta">
				&copy; Northern Star Seed LLC
			</div>
			<div class="main-footer__nav">
				<router-link class="main-footer__nav-item" active-class="main-footer__nav-item--active" v-for="(item, key) in navItems" :key="key" :to="item.link">
					{{ item.label }}
				</router-link>
			</div>

		</div>
	</footer>
</template>

<script>
export default {
	name: 'mainFooter',
	computed: {
		navItems() {
			let items = []
			let seeds = this.$store.getters['seeds/seeds']
			seeds.forEach(seed => {
				items.push({
					label: seed.label,
					link: {
						name: 'seed',
						params: {
							seedId: seed.key,
						},
					}
				})
			})

			items.push({
				label: 'Contact Us',
				link: {
					name: 'contact',
				}
			})

			return items
		},
	},
}
</script>

<style scoped lang="scss">
.main-footer {
	background-color: modules.color_('secondary');
	border-top: 4px solid white;

	&__content {
		@include modules.gutter('padding-bottom');
		@include modules.gutter('padding-top');
		@include modules.gutter('margin-bottom', 0);
		color: modules.color_('secondary', 'text');
		display: flex;

		@include modules.media-query('phone') {
			align-items: flex-end;
		}
	}

	&__nav {
		display: flex;
		flex: 1;
		align-items: flex-end;
		justify-content: flex-end;
		flex-wrap: wrap;

		@include modules.media-query('phone') {
			flex-direction: column;
		}

		&-item {
			@include modules.gutter('margin-left');
			@include modules.fontSize(16px);
			border-bottom: 3px solid transparent;
			color: modules.color_('secondary', 'text');
			font-weight: 400;
			line-height: 1.6;
			text-decoration: none;
			text-transform: uppercase;
			transition: color 250ms;

			&:hover {
				color: modules.color_('primary');
			}

			&--active {
				color: modules.color_('primary');
			}
		}
	}
}
</style>
