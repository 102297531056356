<template>
	<div class="partial partial--home home">
		<div class="home__hero">
			<!-- <div class="home__hero-content">
			</div> -->
			<video-bg :sources="[{src: `${publicPath}video/homepage.mp4`, type:'video/mp4'}]" />
		</div>
		<div class="home__subhero">
			<div class="home__subhero-content content">
				<h3 class="home__hero-statement">True to our roots since 1999, Northern Star has been focused on delivering industry leading, top-performing genetics for small grains, forages, and field peas. When we select varieties to offer, we put our customers first.</h3>
			</div>
		</div>
		<div class="home-list">
			<router-link class="home-list__item" :to="{name: 'seed', params: {seedId: seed.key}}" v-for="(seed, key) in seeds" :key="key" :style="{'background-image': 'url('+ require(`@/assets/bg_${seed.key}.jpg`) +')'}" v-html="seed.label">
				{{ seed.label }}
			</router-link>
			<router-link class="home-list__item" :to="{name: 'contact'}" :style="{'background-image': 'url('+ require(`@/assets/bg_contact.jpg`) +')'}">
				Contact Us
			</router-link>
		</div>
	</div>
</template>

<script>
import VideoBg from '@/components/VideoBg'

export default {
	name: 'partialHome',
	components: {
		VideoBg,
	},
	data: () => ({
		publicPath: process.env.BASE_URL,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		seeds() {
			return this.$store.getters['seeds/seeds']
		},
	},
	mounted() {
		this.$store.dispatch('seeds/list')
	},
}
</script>

<style scoped lang="scss">
$border-width: 4px;
.home {

	&__hero {
		@include modules.ratio('film');
		background-color: modules.color_('primary');
		border-bottom: $border-width solid white;

		&-content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			max-width: map-get(modules.$widths,'desktop');
			text-align: center;
			width: 100%;
		}

		&-title {
			@include modules.fontSize(28px);
			color: modules.color_('primary', 'text');
			font-weight: 900;
			text-transform: uppercase;
			text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.7);
		}

		&-statement {
			@include modules.fontSize(22px);
			font-weight: 500;

			@include modules.media-query('phone') {
				@include modules.fontSize(18px);
			}
		}
	}

	&__subhero {
		background-color: modules.color_('primary');
		border-bottom: $border-width solid white;

		&-content {
			align-items: center;
			color: modules.color_('primary', 'text');
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
		}
	}

	&-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: $border-width;
		row-gap: $border-width;

		@include modules.media-query('phone') {
			grid-template-columns: 1fr;
		}

		&__item {
			@include modules.gutter('padding');
			@include modules.ratio('wide');
			@include modules.fontSize(90px);
			align-items: center;
			background-color: rgba(0, 0, 0, 0.5);
			background-size: cover;
			background-blend-mode: multiply;
			background-position: center center;
			color: rgba(modules.color_('primary', 'text'), 0.5);
			display: flex;
			font-weight: 900;
			justify-content: center;
			letter-spacing: 1px;
			position: relative;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: 250ms color, 250ms background;

			@include modules.media-query('tablet') {
				@include modules.fontSize(56px);
			}

			@include modules.media-query('phone') {
				@include modules.fontSize(36px);
			}

			&:hover {
				// background-blend-mode: normal;
				background-color: rgba(0, 0, 0, 0.15);
				color: modules.color_('primary', 'text');
			}
		}
	}
}
</style>
